<template>
  <div>
    <apexchart ref="chart" :width="chartWidth" :height="chartHeight" :options="chartOptions" :series="fromSeries"></apexchart>
  </div>
</template>
<script>

import moment from "moment";
//spo2


function getChartOptions(chartTitle,dayCount,dayFromTo,ymin,ymax,xTitle,yTitle,colors){
  let xaxisAmount = 0;
  if(dayCount == 1){
    xaxisAmount = 12;
  }else {
    xaxisAmount = dayCount;
    if(xaxisAmount >= 30){
      xaxisAmount = 15;
    }
  }
  return {
    fill: {
      colors: colors,
      opacity:1,
    },
    chart: {
      // height: 350,
      type: 'scatter',

      animations:{
        enabled:false
      },
      zoom: {
        type: 'x'
      },
      toolbar: {
        show:false,
        tools:{
          download: false,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,// pan: true,// reset: true,// customIcons: []
        }
      },
    },
    title: {
      text: chartTitle,
      align: 'center',
      offsetX: 0,
      offsetY: 5,
      floating: true,
      style: {
        fontSize:  '17px',
        fontWeight:  'bold',
        // fontFamily:  undefined,
        color: '#263238'
      },
    },
    animations: {enabled: false},
    // markers: {size: 0,},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
    },
    xaxis: {
      title:{text:xTitle},
      type: 'datetime',
      tickAmount:xaxisAmount,
      min: new Date(dayFromTo.from).getTime(),
      max: new Date(dayFromTo.to).getTime(),
      labels:{
        show:true,
        formatter: function (val,opts,tt) {
          if(dayCount == 1){
            return moment(val).format("H時");
          }
          return moment(val).format("MM/DD");
        }
      },
      tooltip: {
        enabled: true,
        formatter: function(val, opts) {
          return moment(val).format("YYYY-MM-DD HH:mm:ss");
        }
      },
    },
    yaxis:{
      title:{text:yTitle,offsetX: 8},
      min:ymin,
      max:ymax,
      tickAmount: 4
    }
  };
}
export default {
  name: "Spo2Charts",
  props: {
    dayCount: {
      required: true
    },
    dayFromTo:{
      type:Object,
      "default":() => ({from:"2021-04-27 00:00:00",to:"2021-04-28 00:00:00"})
    },
    colors:{type:Array},
    ymin:{type:Number,default:0},
    ymax:{type:Number,default:300},
    // series: {type: Array, required: false},
    fromDate:{},
    chartTitle:{default:"SpO2",String},
    chartWidth:{default:"100%"},
    chartHeight:{default:"200"},
    fromSeries:{type: Array},
    xTitle:{type:String},
    yTitle:{type:String},
  },
  mounted(){
    // this.getData();
  },
  methods:{
    getData(){
      // function generateDayWiseTimeSeries(baseval, count, yrange) {
      //   var i = 0;
      //   var series = [];
      //   while (i < count) {
      //     var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
      //
      //     // series.push([baseval, y]);
      //     series.push([moment(baseval).format("YYYY-MM-DD HH:mm"), y]);
      //     // baseval += 86400000;
      //     baseval += 60 * 1000 * 60;
      //     i++;
      //   }
      //   return series;
      // }
      //
      // let highDataList = generateDayWiseTimeSeries(new Date('2017/01/11').getTime(), 24, {
      //   min: 0,
      //   max: 100
      // });
      //
      // let downDataList = generateDayWiseTimeSeries(new Date('2017/01/11').getTime(), 24, {
      //   min: 0,
      //   max: 100
      // });

      // this.series = [
      //   {name:"high",data:highDataList},
      //   {name:"down",data:downDataList},
      // ];
      //
      // console.log("------------------------------------------",JSON.stringify(this.series));
    }
  },
  data(){
    return {
    chartFrom:null,
    chartMode:7,
      chartOptions: getChartOptions(this.chartTitle, this.dayCount, this.dayFromTo, this.ymin, this.ymax,this.xTitle,this.yTitle,this.colors,this.fromSeries)
  }},
  watch:{
    fromSeries(){
      this.chartOptions = getChartOptions(this.chartTitle,this.dayCount,this.dayFromTo,this.ymin,this.ymax,this.xTitle,this.yTitle,this.colors,this.fromSeries);
    }
  }
};
</script>
<style>
</style>
